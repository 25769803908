// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$startapp-new-primary: mat-palette($mat-indigo);
$startapp-new-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$startapp-new-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$startapp-new-theme: mat-light-theme(
  (
    color: (
      primary: $startapp-new-primary,
      accent: $startapp-new-accent,
      warn: $startapp-new-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($startapp-new-theme);

@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import "~swiper/swiper-bundle";

@-ms-viewport {
  zoom: 1;
  width: device-width;
}
@-o-viewport {
  zoom: 1;
  width: device-width;
}
@viewport {
  zoom: 1;
  width: device-width;
}

body {
  font-family: "NanumSquare", sans-serif;
}
.normal {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.bolder {
  font-weight: 800;
}
.light {
  font-weight: 300;
}

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body {
  height: 100%;
  font-size: 18px;
}
// @media screen and (max-width: 1023px) {
//   html,
//   body {
//     font-size: 16px;
//   }
// }

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
em,
img,
ins,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
audio,
video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  font-family: "NanumSquare", "Roboto", sans-serif;
  word-break: keep-all;
}

body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  box-sizing: border-box;
  display: block;
}
ul,
li,
dl,
dd,
dt {
  box-sizing: border-box;
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: #000;
  box-sizing: border-box;
}
input,
select,
button {
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
small {
  font-size: 80%;
}
img {
  border-style: none;
  display: block;
}

address {
  font-style: normal;
}
button,
input,
textarea,
select,
a {
  border: 0;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

/** Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/** * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/** * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/** * 1. Add the correct box sizing in IE 10. * 2. Remove the padding in IE 10. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/** * Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/** * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/** * Remove the inner padding in Chrome and Safari on macOS. */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/** * 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

button {
  cursor: pointer;
}

/*reset ��*/

#main {
  min-width: 479px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
  z-index: 10;
  // padding-top: 379px;
}

.cf:after {
  content: "";
  clear: both;
  display: block;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.away {
  position: absolute;
  left: -999em;
}

/***라인있는 title****/

.title-area {
  padding: 0 30px 6px 10px;

  h2 {
    font-size: 2.667rem;
  }
}

footer-share {
  position: relative;
  top: -650px;
  z-index: -99;
  width: 100%;
  height: auto;
  transition: top ease-in-out 1s;
  #footer {
    min-width: 479px;
  }
  &.footer-open {
    top: 0;
    z-index: 1;
    transition: top ease-in-out 1s;
  }
}

.none-footer {
  display: none;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

// #mainFooter {
//   position: relative;
//   top: -650px;
//   z-index: -999;
//   will-change: top;
//   transition: top ease-in-out 1s;
//   footer {
//     position: absolute;
//     bottom: 0;
//   }
//   &.footer-open {
//     z-index: 1;
//     top: 0;
//     will-change: top;
//     transition: top ease-in-out 1s;
//   }
// }

.btns-wrap {
  background: #07194e;
  padding: 0 10px;
  .btns-inner {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 0 160px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    max-width: 210px;
    height: 50px;
    font-size: 1rem;
    color: #fff;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-color: transparent;
    white-space: pre-line;
    word-break: break-all;
    a {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  .prev-btn {
    left: 0;
    padding-left: 70px;
    background-image: url(./assets/imgs/prev_icon.png);
    background-position: left center;
    padding-right: 0;
    text-align: left;
  }
  .next-btn {
    right: 0;
    padding-right: 70px;
    background-image: url(./assets/imgs/next_icon.png);
    background-position: right center;
    padding-left: 0;
    text-align: right;
  }
  p {
    font-size: 1.333rem;
    color: #fff;
    line-height: 30px;
  }
}

//******텍스트 애니메이션 공통 css

// .txt-ani {
//   transition: clip-path 1s, transform 1s, opacity 1s;
//   clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
//   transform: translate3d(0, 1em, 0) skewY(5deg);
//   transition: all ease-in-out 1s;
// }

// .txt-ani-ac {
//   clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//   transform: translate3d(0, 0, 0) skewY(0deg);
//   transition: all ease-in-out 1s;
// }

.swiper-pagination {
  width: 100%;
  margin-bottom: 60px;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-right: 50%;
    border-radius: 50%;
    margin-right: 10px;
    background: #ebebeb;
    opacity: 1;
    &:last-child {
      margin-right: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background: #1a3070;
    opacity: 1;
  }
}

.guide-wrap {
  .swiper-button-prev {
    display: inline-block;
    width: 12px;
    height: 20px;
    background: url(./assets/imgs/slide-prev.png) no-repeat center / contain;
    position: absolute;
    left: calc(50% - 195px);
    &::after {
      display: none;
    }
  }
  .swiper-button-next {
    display: inline-block;
    width: 12px;
    height: 20px;
    background: url(./assets/imgs/slide-next.png) no-repeat center / contain;
    position: absolute;
    right: calc(50% - 195px);
    &::after {
      display: none;
    }
  }
}

// **************** design & marketing & inquiry & portfolio **************** //
// video section
.main-video,
.main-bg {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 200px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .main-text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    h2 {
      margin-bottom: 1.12rem;
      font-size: 2rem;
    }
    p {
      font-size: 1.17rem;
      line-height: 1.722rem;
    }
  }
  .scroll-icon {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50px, -50%);
    padding-top: 77px;
    background: url(assets/imgs/scroll.gif) no-repeat center top / 36px 60px;
    p {
      color: #fff;
      opacity: 0.5;
      font-size: 0.6111rem;
      letter-spacing: 0.3889rem;
      text-indent: 0.3889rem;
      text-align: center;
    }
  }
}
// portfolio header
.detail-header {
  width: 100%;
  height: auto;
  padding: 136px 30px 30px;
  background-color: #0a0d15;
  h1 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 2rem;
    line-height: 2.22rem;
    font-weight: 800;
  }
  p {
    color: #fff;
    font-size: 1.1667rem;
    line-height: 1.722rem;
    font-weight: 300;
  }
}

.appintro-wrap {
  padding: 120px 0 200px;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 120px;
  }
  .icon {
    width: 180px;
    height: 180px;
    margin-right: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .txt-wrap {
    h3 {
      margin-bottom: 10px;
      font-size: 2rem;
      line-height: 2.22rem;
      &::after {
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        margin-top: 20px;
        background-color: #000;
      }
    }
    p {
      margin-bottom: 10px;
      line-height: 1.778rem;
      word-break: keep-all;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .small {
      line-height: initial;
      font-size: 0.889rem;
      span {
        margin-right: 10px;
        font-size: 0.889rem;
      }
    }
  }
}
.appintro-wrap {
  .swiper-container {
    margin-bottom: 50px;
    overflow: hidden;
    max-width: 1200px;
    li,
    .swiper-slide {
      width: 280px;
      // width: 280px !important;
      max-height: 498px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .big-area {
    display: block;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: "";
  }
  // .swiper-button-prev,
  // .swiper-button-next {
  //   display: none;
  // }
  .swiper-button-prev {
    display: block;
    left: 0;
    width: 44px;
    height: 44px;
    background: url(assets/imgs/portfolio_slide_prev.png) no-repeat center /
      44px;
    opacity: 1;
    cursor: pointer;
  }
  .swiper-button-next {
    display: block;
    right: 0;
    width: 44px;
    height: 44px;
    background: url(assets/imgs/portfolio_slide_next.png) no-repeat center /
      44px;
    opacity: 1;
    cursor: pointer;
  }
  .middel-area {
    display: none;
  }
}

.explanation-txt {
  h4 {
    margin-bottom: 20px;
    font-size: 1.333rem;
    font-weight: 800;
    word-break: keep-all;
  }
  p {
    line-height: 1.778rem;
    word-break: keep-all;
  }
}

//푸터설정
.mobile-footer {
  display: none;
}

// #footerWrap {
//   display: block;
// }
@media screen and (max-width: 1199px) {
  .appintro-wrap {
    .big-area {
      display: none;
    }

    .middel-area {
      display: block;
    }
  }
  //   .swiper-button-prev {
  //     display: block;
  //     left: 0;
  //     width: 44px;
  //     height: 44px;
  //     background: url(assets/imgs/portfolio_slide_prev.png) no-repeat center /
  //       44px;
  //     opacity: 1;
  //     cursor: pointer;
  //   }
  //   .swiper-button-next {
  //     display: block;
  //     right: 0;
  //     width: 44px;
  //     height: 44px;
  //     background: url(assets/imgs/portfolio_slide_next.png) no-repeat center /
  //       44px;
  //     opacity: 1;
  //     cursor: pointer;
  //   }
  // }
}

@media screen and (max-width: 1023px) {
  .appintro-wrap {
    .btn-area {
      display: none;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .service-txt p {
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  html,
  body {
    font-size: 15px;
    // min-height: calc(100% + 224px);
    // height: auto;
  }
  #main {
    min-width: 100%;
  }

  footer-share {
    #footer {
      min-width: 100%;
    }
  }

  .mobile-footer {
    display: block;
  }

  #footerWrap {
    display: none;
  }

  .mobile-footer {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .service-txt {
    h4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  .appintro-wrap {
    .top {
      flex-direction: column;
      margin-bottom: 140px;
    }
    .icon {
      margin-right: 0;
      margin-bottom: 60px;
    }
    .txt-wrap {
      h3 {
        margin-bottom: 20px;
        text-align: center;
        &::after {
          margin: 20px auto;
        }
      }
      p {
        text-align: center;
      }
    }
  }

  .explanation-txt {
    text-align: center;
  }

  /*
  *
  간편견적 팝업
  */

  .popup-wrap {
    max-width: 70% !important;
    width: 100%;
  }

  .btns-wrap {
    .btns-inner {
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 10px 110px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      width: 36%;
    }
  }
}

@media screen and (max-width: 550px) {
  html,
  body {
    font-size: 14px;
    // min-height: calc(100% + 224px);
    // height: auto;
  }
  .main-video,
  .main-bg {
    .main-text {
      padding: 0 10px;
    }
  }

  .btns-wrap {
    .prev-btn {
      padding-left: 3.8889rem;
    }

    .next-btn {
      padding-right: 3.8889rem;
    }

    .btns-inner {
      padding: 10px 140px;
    }
    button {
      width: 32%;
      background-size: 2.7778rem 2.7778rem;
    }

    p {
      font-size: 1.1rem;
      color: #fff;
      line-height: 30px;
    }
  }
}
// width 1180px
.w1180 {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 200px;
}
// width 1280px
.w1280 {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
// section title
.section-title {
  margin-bottom: 100px;
  h2 {
    margin-bottom: 1.12rem;
    font-size: 2.6667rem;
    font-weight: 800;
    line-height: 3.6rem;
    word-break: keep-all;
  }
  p {
    font-size: 1.166rem;
    line-height: 1.778rem;
    word-break: keep-all;
    span {
      font-size: 1.166rem;
    }
  }
}
// **************** design & marketing & inquiry & portfolio **************** //

// ***** service-menu
@media screen and (max-width: 1023px) {
  #serviceHeader #header,
  #serviceHeader #mHeader {
    position: relative;
    left: 0;
    top: 0;
    background: #000;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

///////mat-card reset
.mat-card {
  .mat-card-header {
    padding: 0;
    margin: 0;
    .mat-card-header-text {
      margin: 0;
      padding: 0;
      .mat-card-title {
        margin: 0;
        padding: 0;
      }
    }
  }

  .mat-card-actions,
  .mat-card-subtitle,
  .mat-card-content {
    margin: 0;
    margin-bottom: 0;
  }
  .mat-card-actions {
    margin: 0;
    padding: 0;
    .mat-button-toggle {
      border-radius: 0;
    }
    span {
      padding: 0;
    }
  }
}
.mat-card > .mat-card-actions:last-child {
  margin-bottom: 0 !important;
}

#header {
  &.on {
    top: 0;
    transition: top ease-in-out 0.2s;
  }
  &.off {
    top: -56px;
    transition: top ease-in-out 0.2s;
  }
}

.popup-wrap {
  max-width: 500px;
  min-width: 479px;
  width: 100%;

  .mat-dialog-container {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.load-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 990;
  display: flex;
  background: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  border-radius: 24px;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.2s;
  &.active {
    width: 300px;
    height: 170px;

    opacity: 1;
    transition: opacity ease-in 0.2s;
  }

  .logo-icon {
    width: 62px;

    height: auto;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .loading-icon {
    width: 40px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #07194e;
  }
}

a {
  cursor: pointer;
}

.bottom-box,
.bottom_box {
  h2 {
    width: 280px !important;
    height: 70px !important;
    text-indent: -9999px !important;
    display: block !important;
    background: url(./assets/imgs/bottom-logo.png) no-repeat top left / contain !important;
    margin-bottom: 20px !important;
  }
}
